
import Vue from 'vue';
import TransitionFade from '@/transitions/Transition-fade.vue';

export default Vue.extend({
  name: 'Blog',
  components: {
    TransitionFade,
  },
});
